'use client';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMessages } from '@fortawesome/pro-light-svg-icons';
import { faArrowRightToBracket } from '@fortawesome/pro-regular-svg-icons';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, checkIfSessionIsActive } from '@get-mosh/msh-shared';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { DestinationCategory, PageType } from 'src/analytics/EventProps';
import { trackNavigationItemClicked } from 'src/analytics/Events';
import Routes from 'src/constants/Routes';
import { Vertical } from 'src/constants/Verticals';
import { env } from 'src/env.mjs';
import { clearBodyLocks, lock } from 'tua-body-scroll-lock';

import common from '../../../data/common.json';
import styles from './side-navigation.module.scss';

type SideNavigationLink = {
    href: string;
    label: string;
    icon: IconProp;
    destination_category: DestinationCategory;
};

type SideNavigationProps = {
    links: SideNavigationLink[];
    pageType?: PageType;
    vertical?: Vertical;
};

const SideNavigation = (props: SideNavigationProps) => {
    const [sessionActive, setSessionActive] = useState(false);

    const handleClick = () => {
        trackNavigationItemClicked({
            destination_category: DestinationCategory.PREQUIZ,
            destination_url: env.NEXT_PUBLIC_BASE_PATH + Routes.QUIZZES,
            label: common.startYourFreeConsult,
            page_type: props.pageType,
            vertical: props.vertical,
        });
    };

    useEffect(() => {
        if (checkIfSessionIsActive()) {
            setSessionActive(true);
        }
    }, []);

    return (
        <aside className={styles['header--buttons']}>
            <Button
                as="a"
                text={common.startYourFreeConsult}
                className={styles['header--button']}
                href={Routes.QUIZZES}
                onClick={() => handleClick()}
            />
            {sessionActive ? (
                <Link
                    href={Routes.NOTIFICATIONS}
                    className={styles['header--account-button']}
                    onClick={() => {
                        trackNavigationItemClicked({
                            destination_category:
                                DestinationCategory.USER_PORTAL,
                            destination_url:
                                env.NEXT_PUBLIC_BASE_PATH + Routes.SIGN_IN(),
                            label: 'My account',
                            page_type: props.pageType,
                            vertical: props.vertical,
                        });
                    }}
                >
                    <Image
                        src="/images/icons/avatar.svg"
                        width={48}
                        height={48}
                        alt={common.myAccount}
                    />
                </Link>
            ) : (
                <Button
                    as="a"
                    href={Routes.SIGN_IN()}
                    text={common.signIn}
                    onClick={() => {
                        trackNavigationItemClicked({
                            destination_category:
                                DestinationCategory.USER_PORTAL,
                            destination_url:
                                env.NEXT_PUBLIC_BASE_PATH + Routes.SIGN_IN(),
                            label: 'Sign in',
                            page_type: props.pageType,
                            vertical: props.vertical,
                        });
                    }}
                    variant="secondary"
                    className={styles['header--button']}
                />
            )}
            <Navigation
                links={props.links}
                pageType={props.pageType}
                vertical={props.vertical}
                className={styles['navigation']}
                sessionActive={sessionActive}
            />
        </aside>
    );
};

type NavigationProps = {
    links: SideNavigationLink[];
    className?: string;
    pageType?: PageType;
    vertical?: Vertical;
    sessionActive: boolean;
};

const Navigation = (props: NavigationProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const navContentRef = useRef<HTMLDivElement | null>(null);
    const iconToggle = isOpen ? faXmark : faBars;

    const toggleNav = () => {
        setIsOpen(!isOpen);
        !isOpen &&
            trackNavigationItemClicked({
                label: 'Hamburger',
            });
    };

    useEffect(() => {
        isOpen && window.innerWidth < 576
            ? lock(navContentRef.current)
            : clearBodyLocks();
        document
            .getElementById('headerNavigation')
            ?.style.setProperty('overflow', isOpen ? 'initial' : 'hidden');
    }, [isOpen]);

    const handleTakeFreeQuizClick = () => {
        trackNavigationItemClicked({
            destination_category: DestinationCategory.PREQUIZ,
            destination_url: env.NEXT_PUBLIC_BASE_PATH + Routes.QUIZZES,
            label: common.startYourFreeConsult,
            page_type: props.pageType,
            vertical: props.vertical,
        });
    };

    return (
        <nav className={classNames(props.className)} data-nav-open={isOpen}>
            <button
                onClick={toggleNav}
                aria-label={isOpen ? 'Open' : 'Close' + ' menu'}
                className={styles['navigation--toggle']}
            >
                <FontAwesomeIcon icon={iconToggle} width={16} height={16} />
            </button>
            <div className={styles['navigation--container']}>
                <div
                    className={styles['navigation--content']}
                    ref={navContentRef}
                >
                    <SideNavigationLinks
                        links={props.links}
                        pageType={props.pageType}
                        vertical={props.vertical}
                        sessionActive={props.sessionActive}
                    />
                    <div className={styles['navigation--buttons']}>
                        {props.sessionActive ? null : (
                            <Button
                                as="a"
                                href={Routes.SIGN_IN()}
                                variant="secondary"
                                text={common.signIn}
                            />
                        )}
                        <p className={styles['divider']}>
                            <span>Not sure where to start?</span>
                        </p>
                        <Button
                            as="a"
                            href={Routes.QUIZZES}
                            text={common.startYourFreeConsult}
                            onClick={() => handleTakeFreeQuizClick()}
                        />
                    </div>
                    <div className={styles['bottom-links']}>
                        <a
                            href={Routes.CONTACT_US}
                            onClick={() => {
                                trackNavigationItemClicked({
                                    destination_category:
                                        DestinationCategory.USER_PORTAL,
                                    destination_url:
                                        env.NEXT_PUBLIC_BASE_PATH +
                                        Routes.CONTACT_US,
                                    label: common.contactUs,
                                    page_type: props.pageType,
                                    vertical: props.vertical,
                                });
                            }}
                        >
                            {common.contactUs}
                            <FontAwesomeIcon icon={faMessages} />
                        </a>
                        {props.sessionActive ? (
                            <a
                                href={Routes.SIGN_OUT}
                                onClick={() => {
                                    trackNavigationItemClicked({
                                        destination_category:
                                            DestinationCategory.USER_PORTAL,
                                        destination_url:
                                            env.NEXT_PUBLIC_BASE_PATH +
                                            Routes.SIGN_OUT,
                                        label: common.signOut,
                                        page_type: props.pageType,
                                        vertical: props.vertical,
                                    });
                                }}
                            >
                                {common.signOut}
                                <FontAwesomeIcon icon={faArrowRightToBracket} />
                            </a>
                        ) : null}
                    </div>
                </div>
            </div>
        </nav>
    );
};

type SideNavigationLinksProps = {
    links: SideNavigationLink[];
    className?: string;
    pageType?: PageType;
    vertical?: Vertical;
    sessionActive: boolean;
};

const SideNavigationLinks = ({
    links,
    pageType,
    vertical,
    sessionActive,
}: SideNavigationLinksProps) => {
    const pathname = usePathname();

    const handleSideNavigationLinkClick = (item: SideNavigationLink) => {
        trackNavigationItemClicked({
            destination_category: item.destination_category,
            destination_url: env.NEXT_PUBLIC_BASE_PATH + item.href,
            label: item.label,
            page_type: pageType,
            vertical: vertical,
        });
    };

    return (
        <ul className={styles['navigation--item']}>
            {sessionActive ? (
                <li>
                    <a
                        href={Routes.NOTIFICATIONS}
                        className={styles['profile-item']}
                    >
                        <Image
                            src="/images/icons/avatar.svg"
                            width={32}
                            height={32}
                            alt={common.myAccount}
                        />
                        {common.myAccount}
                    </a>
                </li>
            ) : null}
            {links.map((e: SideNavigationLink, i: number) => (
                <li key={i}>
                    <a
                        href={e.href}
                        title={e.label}
                        onClick={() => handleSideNavigationLinkClick(e)}
                        className={classNames({
                            [styles['navigation--item-active']]:
                                e.href === pathname,
                        })}
                    >
                        {e.icon ? <FontAwesomeIcon icon={e.icon} /> : null}
                        {e.label}
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default SideNavigation;
