'use client';

import Headroom from 'react-headroom';
import { DestinationCategory, PageType } from 'src/analytics/EventProps';
import Routes from 'src/constants/Routes';
import { Vertical } from 'src/constants/Verticals';
import { env } from 'src/env.mjs';

import styles from './header.module.scss';
import MoshLogo from '../../MoshyLogo/MoshyLogo';
import SideNavigation from '../SideNavigation/SideNavigation';
import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { trackClick, trackNavigationItemClicked } from 'src/analytics/Events';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { TopBar } from '@get-mosh/msh-shared';
import EventNames from 'src/analytics/EventNames';
import Link from 'next/link';
import {
    faArrowRightLong,
    faBookOpen,
    faSprayCanSparkles,
    faUserDoctor,
    faWeightScale,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type HeaderProps = {
    pageType?: PageType;
    variant?: 'default' | 'logo-only';
    vertical?: Vertical;
};

const links = [
    {
        label: 'Weight loss',
        icon: faWeightScale,
        href: Routes.WEIGHT_LOSS_LANDING_PAGE,
        destination_category: DestinationCategory.CATEGORY_PAGE,
    },
    {
        label: 'Skincare',
        icon: faSprayCanSparkles,
        href: Routes.SKIN_LANDING_PAGE,
        destination_category: DestinationCategory.CATEGORY_PAGE,
    },
    {
        label: 'Knowledge hub',
        icon: faBookOpen,
        href: Routes.BLOG,
        destination_category: DestinationCategory.BLOG,
    },
    {
        label: 'About us',
        icon: faUserDoctor,
        href: Routes.ABOUT_US,
        destination_category: DestinationCategory.INFO_PAGE,
    },
];

const MOSH_URL = new URL(Routes.MOSH_HOME());

MOSH_URL.searchParams.append('utm_source', 'Moshy');
MOSH_URL.searchParams.append('utm_medium', 'website-banner');
MOSH_URL.searchParams.append('utm_campaign', 'Moshy_Website_Banner-Mosh');
MOSH_URL.searchParams.append('utm_content', 'mosh-banner');
MOSH_URL.searchParams.append('utm_term', 'check-out-mosh-for-him');

const Header = ({ pageType, variant = 'default', vertical }: HeaderProps) => {
    const [topBarHeight, setTopBarHeight] = useState<number | undefined>(0);
    const navigationRef = useRef<HTMLDivElement | null>(null);
    const pathname = usePathname();

    useEffect(() => {
        const containerEl = document.querySelector(
            '[data-header="navigation"]',
        );
        const setContainerOffsetValue = () => {
            containerEl &&
                document
                    .querySelector('html')
                    ?.style.setProperty(
                        '--header-navigation-offset',
                        getComputedStyle(containerEl).marginLeft,
                    );
        };
        setContainerOffsetValue();
        window.addEventListener('resize', () => {
            setContainerOffsetValue();
        });

        document
            .querySelector('html')
            ?.style.setProperty(
                '--header-height',
                navigationRef.current?.clientHeight + 'px',
            );
    }, [topBarHeight]);

    const onLinkClick = (label: string, url: string) => {
        trackClick(EventNames.TOP_BANNER_CLICKED, {
            label,
            destination_url: url,
        });
    };

    const topBarItems = [
        pathname === Routes.HOME
            ? {
                  content: (
                      <a
                          className="flex flex-row items-center gap-2 font-demi"
                          href={MOSH_URL.toString()}
                          onClick={() =>
                              onLinkClick(
                                  'Check out Mosh for him',
                                  MOSH_URL.toString(),
                              )
                          }
                      >
                          Check out Mosh for him 👨🏼
                          <FontAwesomeIcon icon={faArrowRightLong} />
                      </a>
                  ) as ReactNode,
                  screen: 'all' as const,
              }
            : {
                  content: (
                      <b>
                          Free shipping | Certified Aussie Practitioners | 100%
                          online
                      </b>
                  ) as ReactNode,

                  screen: 'all' as const,
              },
    ];

    return (
        <header
            id="mainHeader"
            className={styles['header']}
            data-header="container"
            data-variant={variant}
        >
            {variant === 'logo-only' ? null : (
                <TopBar
                    variant={pathname === Routes.HOME ? 'secondary' : 'primary'}
                    items={topBarItems}
                    onResize={(height) => setTopBarHeight(height)}
                />
            )}
            <Headroom disable={variant === 'logo-only'} pinStart={topBarHeight}>
                <div className={styles['wrapper']} ref={navigationRef}>
                    <section
                        className={styles['header__section']}
                        data-header="navigation"
                    >
                        <nav className={styles['header__navigation']}>
                            {variant === 'logo-only' ? (
                                <MoshLogo
                                    link="/"
                                    variant="dark"
                                    label="Home"
                                    className={styles['header__logo']}
                                />
                            ) : (
                                <>
                                    <MoshLogo
                                        link="/"
                                        variant="dark"
                                        label="Home"
                                        className={styles['header__logo']}
                                    />
                                    <div className={styles['header__links']}>
                                        {links.map(
                                            ({
                                                label,
                                                href,
                                                destination_category,
                                            }) => (
                                                <Link
                                                    key={label}
                                                    href={href}
                                                    onClick={() => {
                                                        trackNavigationItemClicked(
                                                            {
                                                                destination_category,
                                                                destination_url:
                                                                    env.NEXT_PUBLIC_BASE_PATH +
                                                                    href,
                                                                label,
                                                                page_type:
                                                                    pageType,
                                                                vertical,
                                                            },
                                                        );
                                                    }}
                                                    className={classNames({
                                                        [styles[
                                                            'header__link-active'
                                                        ]]: href === pathname,
                                                    })}
                                                >
                                                    {label}
                                                </Link>
                                            ),
                                        )}
                                    </div>
                                </>
                            )}
                        </nav>
                        {variant === 'default' ? (
                            <SideNavigation
                                vertical={vertical}
                                pageType={pageType}
                                links={links}
                            />
                        ) : null}
                    </section>
                </div>
            </Headroom>
        </header>
    );
};

export default Header;
